html {
  height: 100%;
  background-color: #772f1a;
}
body {
  background-color: #f5f3f4;
}

.temp {
  background-color: #f5f3f4;
}

.iconColor {
  filter: invert(6%) sepia(5%) saturate(2537%) hue-rotate(163deg)
    brightness(101%) contrast(91%);
  margin-bottom: 0.6em;
}
.jumbotron {
  box-shadow: 0px 0px 4px grey;
}
.git {
  background-color: white;
}
.card-header {
  background-color: #96897b;
  color: white;
}
.card-header a {
  color: white;
}
.card {
  margin-top: 1.7em;
  box-shadow: 0px 0px 3px #888888;
}
.navbar .navbar-light {
  background-color: #660708;
}
.btnCustom {
  background-color: #772f1a;
  color: white;
}
.hilite {
  color: #772f1a;
  white-space: nowrap;
}

.footer {
  background-color: #772f1a;
  color: white;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-width: 0px;
  height: 5.8em;
}
.verticalLine {
  border-left: thick solid #660708;
}
.proj-media {
  padding-top: 1.2em;
}

.card-body {
  padding-bottom: 0.5em;
}

.buttonRoom {
  padding-bottom: 2.2em;
}

.seperator {
  margin-bottom: 1em;
}

.bigtx {
  margin-top: 2em;
}

.smtx {
  margin-top: 0.5em;
}

.App {
  text-align: center;
}
.header {
  position: relative;
  font-family: Raleway;
  text-align: center;
  width: 100%;
}

.wrapper {
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -155px; /* the bottom margin is the negative value of the footer's height */
}

.footIco {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.upfix {
  margin-top: -0.5em;
}

.homeIcon {
  width: 264px;
}

.sam {
  position: absolute;
  bottom: 0;
  margin-right: 11.5em;
  filter: drop-shadow(0px -0px 2px grey);
}

.leftJumbo {
  width: 40%;
  position: absolute;
  left: 0;
  margin-left: 11.5em;
}
.pageHeader {
  margin-bottom: 0.6em;
}

@media only screen and (min-width: 1640px) {
  .snippets {
    width: 34em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 40em;
  }
  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 20em;
    width: 100%;
  }
  .sam {
    margin-right: 19em;
    right: 0;
    bottom: 0;
  }

  .leftJumbo {
    margin-left: 19em;
  }
}
@media only screen and (max-width: 1639px) and (min-width: 1235px) {
  .snippets {
    width: 32em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 40em;
  }
  .sam {
    right: 0;
    bottom: 0;
    margin-right: 11.5em;
  }
  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 20em;
    width: 100%;
  }
}
@media only screen and (max-width: 1234px) and (min-width: 992px) {
  .snippets {
    width: 30em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 42em;
  }
  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 20em;
    width: 100%;
  }
  .sam {
    right: 0;
    bottom: 0;
    margin-right: 5em;
  }

  .leftJumbo {
    margin-left: 5em;
  }
}

@media only screen and (max-width: 991px) and (min-width: 792px) {
  .snippets {
    width: 26em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 42em;
  }
  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 19em;
    width: 100%;
  }
  .sam {
    bottom: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .shrink {
    margin-left: 50;
    margin-right: 5;
    width: 16em;
  }

  .leftJumbo {
    position: relative;
    margin-left: 0em;
    width: 60%;
  }
  .bigtx {
    margin-top: 0em;
    font-size: 3em;
  }
  .hilite {
    font-size: 2em;
  }
}

@media only screen and (max-width: 791px) and (min-width: 576px) {
  .snippets {
    width: 26em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 40em;
  }
  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 19em;
    width: 100%;
  }
  .sam {
    bottom: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .shrink {
    margin-left: auto;
    margin-right: auto;
    width: 16em;
  }

  .leftJumbo {
    position: relative;
    margin-left: 0em;
    width: 80%;
  }
  .bigtx {
    margin-top: 0em;
    font-size: 2.4em;
  }
  .hilite {
    font-size: 1.5em;
  }
  .footer {
    height: 3.8em;
  }
  .footIco {
    margin-left: 0em;
    margin-right: 0em;
    margin-top: -1.4em;
  }
}

@media only screen and (max-width: 575px) and (min-width: 1px) {
  .snippets {
    width: 26em;
    margin-bottom: 1em;
  }
  .jumbotron {
    background-image: url(./img/bg.jpg);
    background-attachment: scroll;
    height: 36em;
  }

  .header-sm {
    font-family: Raleway;
    text-align: center;
    height: 11.4em;
    width: 100%;
  }
  .sam {
    bottom: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .shrink {
    margin-left: auto;
    margin-right: auto;
    width: 16em;
  }

  .leftJumbo {
    position: relative;
    margin-left: 0em;
    width: 100%;
  }
  .bigtx {
    margin-top: 0em;
    font-size: 2.4em;
  }
  .hilite {
    font-size: 1.2em;
  }
  .footer {
    height: 3.8em;
  }
  .smtx {
    font-size: 2.2em;
  }
  .footIco {
    margin-left: 0em;
    margin-right: 0em;
    margin-top: -1.4em;
  }
  .comein {
    font-size: 0.8em;
  }
  .arrow {
    font-size: 0.2em;
    margin-left: 0.8em;
  }
}
